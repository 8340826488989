import Vue from 'vue'
import Vuetable from 'vuetable-2'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue';
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'
// import $ from 'jquery'
import axios from "axios";
import JsonExcel from 'vue-json-excel'
import vSelect from 'vue-select'
import moment from "moment";
import $ from "jquery";

Vue.component('downloadExcel', JsonExcel)
Vue.component('v-select', vSelect)


export default {
    components: {
        Vuetable,
        VuetablePagination,
        VuetableFieldCheckbox,
        VuetableFieldMixin
    },
    data: function () {
        return {
            pageTitle: "Liste des tresoreries",
            liste_franchise: [],
            motCle: "",
            crudform: {
                id: "",
                libelle_ecoterme: "",
            },
            tab_Ouverture: [],
            Ouverture: {
                id: this.$route.params.id_dossier == undefined ? 0 : this.$route.params.id_dossier,
                nom_client: '',
                etat_dossier: 0,
                ouverture_date_dossier: moment().format('DD/MM/YYYY'),
                ouverture_date_dossier_formatted: '',
                ouverture_numero_tc: [],
                ouverture_bl_attachement: '',
                ouverture_shipper: '',
                ouverture_bl_numero: "",
                ouverture_invoice_attachement: "",
                ouverture_invoice: '',
                ouverture_packing_list_attachement: "",
                ouverture_packing_list: '',
                ouverture_bsc_attachement: "",
                ouverture_circuit: [],
                ouverture_inspecteur: [],
                ouverture_fournisseur: '',
                ouverture_id_client: '',
                ouverture_poids_brut: '',
                ouverture_poids_net: '',
                ouverture_nombre_colis: '',
                ouverture_nature_colis: '',
                ouverture_articles: '',
                ouverture_aet: '',
                ouverture_ge: '',
                ouverture_bsc: '',
                ecoterme: '',
                ouverture_dom: '',
                ouverture_dom_attachement: '',
                ouverture_id_ecoterme: '',
                ouverture_bsc_date_validation: '',
                ouverture_bsc_date_validation_formatted: '',
                ouverture_compagnie_maritime: '',
                ouverture_compapgnie_maritime_attachement: '',
                ouverture_fret_compagn: '',
                ouverture_navire: '',
                ouverture_prevision: '',
                ouverture_prevision_formatted: '',
                ouverture_date_arriver: '',
                ouverture_date_arriver_formatted: '',
                ouverture_commentaire: '',
                ouverture_id_compagnie_maritime: '',
                nom_compagnie_maritime: '',
                date_fermeture_overstay:'',
                declaration_declaration: '',
                declaration_date: '',
                declaration_date_formatted: '',
                declaration_attachement: '',
                declaration_autres: '',
                declaration_douaneusd: '',
                declaration_commentaire: '',
                declaration_refov: '',
                declaration_date_depotbanque: '',
                declaration_date_depotbanque_formatted: '',
                declaration_ddtva: 0,
                declaration_pg: 0,
                declaration_ars: 0,
                declaration_apmf: 0,
                declaration_dad: 0,
                declaration_dc: 0,
                declaration_amde: 0,
                declaration_total_douane: 0,

                chargement_frais_de_dossier: '',
                chargement_dossier_attachment: '',
                chargmenet_quotient: '',
                chargemnet_BAD: '',
                chargement_BAD_attachement: '',
                chargemnet_mictsl_debarg: '',
                chargement_mictsl_attachment: '',
                chargement_restitution: '',
                chargement_restitution_attachement: '',
                chargement_magasinage: '',
                chargement_caution:'',
                chargement_caution_attachement:'',
                chargement_magasinage_attachement: '',
                chargement_onverstays: '',
                chargement_onverstays_attachement: '',
                chargement_EHDN: '',
                chargement_EHDN_attachament: '',
                chargement_environnement: '',
                chargement_environnement_attachement: '',
                chargement_gate_remis: '',
                chargement_gate_remis_attachement: '',
                chargement_cheqocesp1: 0,
                chargement_cheqocesp2: 0,
                chargement_cheqocesp3: 0,
                chargement_cheqocesp4: 0,
                chargement_cheqocesp5: 0,
                chargement_cheqocesp6: 0,
                chargement_cheqocesp7: 0,
                chargement_cheqocesp_total: 0,
                chargement_photo_visite: '',
                chargement_commentaire: '',
                tc_en_visite: [],
                chargementsphotos: [],
                photo_visite: [],

                liquidation_date_r: '',
                liquidation_date_r_formatted: '',
                liquidation_date_l: '',
                liquidation_date_l_formatted: '',
                liquidation_montant_dc: 0,
                liquidation_montant_doc_attachement: '',
                liquidation_date_dc: '',
                liquidation_date_dc_formatted: '',
                liquidation_amende: '',
                liquidation_amende_attachement: '',
                liquidation_date_amende: '',
                liquidation_date_amende_formatted: '',
                liquidation_date_liquidation: '',
                liquidation_date_liquidation_formatted: '',
                liquidation_autre1: '',
                liquidation_autre2: '',
                liquidation_commentaire: '',
                liquidation: '',

                note_prix_date: '',
                note_prix_date_formatted: '',
                note_prix_commentaire: '',
                note_prix_frais_banque: 0,
                note_prix_autres_frais: 0,
                note_prix_transports: 0,
            },
            id_dossier: this.$route.params.id_dossier == undefined ? 0 : this.$route.params.id_dossier,
            nbre_jours_restant: 0,
            facture_attachement:'',
            tresorerie_attachement:'',
        }
    },
    computed: {
        calculateGrandTotal() {
            let grandTotal = 0;
            for (let f of this.liste_franchise) {
                const maxNumberJour = parseInt(this.getMaxNumberJour(f.lite_jour));
                const tarif = parseFloat(f.tarif);
                const subtotal = maxNumberJour * tarif;
                grandTotal += isNaN(subtotal) ? 0 : subtotal;
            }
            return grandTotal;
        }
    },
    methods: {

        clickEnregistrer() {
            $('#enregistrer').trigger('click');
        },

        affichageListeFranchise(list_franchises) {
            this.liste_franchise = [];
            this.nbre_jours_restant = 0;

            var liste_franchise = list_franchises;

            // Date donnée au format 'YYYY-MM-DD'
            var dateDonnee = new Date(this.Ouverture.ouverture_date_arriver);
            var dateAujourdhui = (this.Ouverture.date_fermeture_overstay =='' ||
                                  this.Ouverture.date_fermeture_overstay == null)
                ? new Date() :  new Date(this.Ouverture.date_fermeture_overstay);

            var differenceEnMillisecondes = dateAujourdhui - dateDonnee;
            console.log('date_aujourdhuie',dateAujourdhui);
            // nombre de jours aujourd'hui
            var differenceEnJours = (Math.floor(differenceEnMillisecondes / (1000 * 60 * 60 * 24))) + 1;


            this.nbre_jours_restant = differenceEnJours;
            var ordreSelectionne = null;
            console.log("differencejours", differenceEnJours);
            for (var i = 0; i < liste_franchise.length; i++) {
                var franchise = liste_franchise[i];
                // console.log("nbre_jours",franchise.nbre_jour)
                console.log("nbre_jours_restant", differenceEnJours);
                if (parseInt(franchise.nbre_jour) >= differenceEnJours) {
                    console.log('iteraton', i)
                    ordreSelectionne = franchise.ordre;
                    break;
                }
                differenceEnJours = differenceEnJours - parseInt(franchise.nbre_jour);
            }

            // var nbre_jours = parseInt(this.nbre_jours_restant);// 10
            console.log('valeur_ordre seleection',ordreSelectionne)
            var date_ouverture_date_arriver = this.Ouverture.ouverture_date_arriver;
            if (ordreSelectionne !== null) {
                for (var j = 0; j < liste_franchise.length; j++) {
                    var franchises = liste_franchise[j];
                    var date = "";
                    if (franchises.ordre <= ordreSelectionne) {
                        var nbre_jours = parseInt(this.nbre_jours_restant);
                        var franchiseData = [];
                        var iteration_date = 0;
                        for (var jour = 1; jour <= parseInt(franchises.nbre_jour); jour++) {
                            nbre_jours--;
                            date = moment(date_ouverture_date_arriver).add(iteration_date, 'days').format('DD/MM/YYYY');
                            franchiseData.push({
                                number_jour: jour,
                                etat_jours: nbre_jours >= 0 ? 'expirer' : 'non_expirer',
                                date: date,
                            });
                            iteration_date++;
                        }
                        franchises.lite_jour = franchiseData;
                        this.liste_franchise.push(franchises);
                        this.nbre_jours_restant = nbre_jours;

                        const [jours, mois, annee] = date.split("/");
                        var date1 = annee + "-" + mois + "-" + jours;
                        console.log("new_date", date1)
                        date_ouverture_date_arriver = moment(date1).add(1, 'days');
                    }
                }
            }
        },

        chargeDonneOuverture(ouverture_bl_numero) {
            if (ouverture_bl_numero != null) {
                var formulaire = new FormData();
                formulaire.append('numero_bl',ouverture_bl_numero);
                axios.post(this.BASE_URL + '/dossier/getparbl',formulaire).then((response) => {
                    if (response.data.length > 0){
                        this.Ouverture = response.data[0];
                        /*if ((this.Ouverture.ouverture_date_arriver != '' || this.Ouverture.ouverture_date_arriver != null) &&
                            (this.Ouverture.chargement_onverstays_attachement == '' || this.Ouverture.chargement_onverstays_attachement == null)) {
                            // on prend la valeur selon le paramtre
                            // this.getFranchise(response.data[0].ouverture_id_compagnie_maritime);
                        } else { // on affiche a parti du donnée enregistrer*/
                        this.affichageListeFranchise(response.data[0].overstay);
                        this.facture_attachement = response.data[0].facture;
                        this.tresorerie_attachement = response.data[0].tresorerie;
                        // }
                    }else{
                        this.liste_franchise = [];
                        this.Ouverture = {
                            id: 0,
                            nom_client: '',
                            etat_dossier: 0,
                            ouverture_date_dossier: moment().format('DD/MM/YYYY'),
                            ouverture_date_dossier_formatted: '',
                            ouverture_numero_tc: [],
                            ouverture_bl_attachement: '',
                            ouverture_shipper: '',
                            ouverture_bl_numero: "",
                            ouverture_invoice_attachement: "",
                            ouverture_invoice: '',
                            ouverture_packing_list_attachement: "",
                            ouverture_packing_list: '',
                            ouverture_bsc_attachement: "",
                            ouverture_circuit: [],
                            ouverture_inspecteur: [],
                            ouverture_fournisseur: '',
                            ouverture_id_client: '',
                            ouverture_poids_brut: '',
                            ouverture_poids_net: '',
                            ouverture_nombre_colis: '',
                            ouverture_nature_colis: '',
                            ouverture_articles: '',
                            ouverture_aet: '',
                            ouverture_ge: '',
                            ouverture_bsc: '',
                            ecoterme: '',
                            ouverture_dom: '',
                            ouverture_dom_attachement: '',
                            ouverture_id_ecoterme: '',
                            ouverture_bsc_date_validation: '',
                            ouverture_bsc_date_validation_formatted: '',
                            ouverture_compagnie_maritime: '',
                            ouverture_compapgnie_maritime_attachement: '',
                            ouverture_fret_compagn: '',
                            ouverture_navire: '',
                            ouverture_prevision: '',
                            ouverture_prevision_formatted: '',
                            ouverture_date_arriver: '',
                            date_insertion_overstay: '',
                            ouverture_date_arriver_formatted: '',
                            ouverture_commentaire: '',
                            ouverture_id_compagnie_maritime: '',
                            nom_compagnie_maritime: '',

                            declaration_declaration: '',
                            declaration_date: '',
                            declaration_date_formatted: '',
                            declaration_attachement: '',
                            declaration_autres: '',
                            declaration_douaneusd: '',
                            declaration_commentaire: '',
                            declaration_refov: '',
                            declaration_date_depotbanque: '',
                            declaration_date_depotbanque_formatted: '',
                            declaration_ddtva: 0,
                            declaration_pg: 0,
                            declaration_ars: 0,
                            declaration_apmf: 0,
                            declaration_dad: 0,
                            declaration_dc: 0,
                            declaration_amde: 0,
                            declaration_total_douane: 0,

                            chargement_frais_de_dossier: '',
                            chargement_dossier_attachment: '',
                            chargmenet_quotient: '',
                            chargemnet_BAD: '',
                            chargement_BAD_attachement: '',
                            chargemnet_mictsl_debarg: '',
                            chargement_mictsl_attachment: '',
                            chargement_restitution: '',
                            chargement_restitution_attachement: '',
                            chargement_magasinage: '',
                            chargement_magasinage_attachement: '',
                            chargement_onverstays: '',
                            chargement_onverstays_attachement: '',
                            chargement_EHDN: '',
                            chargement_EHDN_attachament: '',
                            chargement_environnement: '',
                            chargement_environnement_attachement: '',
                            chargement_gate_remis: '',
                            chargement_gate_remis_attachement: '',
                            chargement_cheqocesp1: 0,
                            chargement_cheqocesp2: 0,
                            chargement_cheqocesp3: 0,
                            chargement_cheqocesp4: 0,
                            chargement_cheqocesp5: 0,
                            chargement_cheqocesp6: 0,
                            chargement_cheqocesp7: 0,
                            chargement_cheqocesp_total: 0,
                            chargement_photo_visite: '',
                            chargement_commentaire: '',
                            tc_en_visite: [],
                            chargementsphotos: [],
                            photo_visite: [],

                            liquidation_date_r: '',
                            liquidation_date_r_formatted: '',
                            liquidation_date_l: '',
                            liquidation_date_l_formatted: '',
                            liquidation_montant_dc: 0,
                            liquidation_montant_doc_attachement: '',
                            liquidation_date_dc: '',
                            liquidation_date_dc_formatted: '',
                            liquidation_amende: '',
                            liquidation_amende_attachement: '',
                            liquidation_date_amende: '',
                            liquidation_date_amende_formatted: '',
                            liquidation_date_liquidation: '',
                            liquidation_date_liquidation_formatted: '',
                            liquidation_autre1: '',
                            liquidation_autre2: '',
                            liquidation_commentaire: '',
                            liquidation: '',

                            note_prix_date: '',
                            note_prix_date_formatted: '',
                            note_prix_commentaire: '',
                            note_prix_frais_banque: 0,
                            note_prix_autres_frais: 0,
                            note_prix_transports: 0,
                        }
                    }
                })
            }
        },
        chargeDonneOuverturebyID(id) {
            if (id != null) {
                axios.get(this.BASE_URL + '/dossier/get/'+id).then((response) => {
                    if (response.data.length > 0){
                        this.Ouverture = response.data[0];
                        this.affichageListeFranchise(response.data[0].overstay);
                    }
                })
            }
        },

        getAllDossier() {
            axios.get(this.BASE_URL + '/dossier/fetchdata').then((response) => {
                this.tab_Ouverture = response.data.dossier;
            })
        },

        affiche_piece_jointe(nom_piece_jointe) {
            console.log(nom_piece_jointe);
            if ((nom_piece_jointe != '' && nom_piece_jointe != null)) {
                window.open(this.BASE_URL + "/upload/dossier/" + nom_piece_jointe, '_blank');
            }
        },
        affiche_piece_jointe_others(nom_piece_jointe,sub_folder) {
            console.log(nom_piece_jointe);
            if ((nom_piece_jointe != '' && nom_piece_jointe != null)) {
                window.open(this.BASE_URL + "/upload/dossier/"+sub_folder+"/"+ nom_piece_jointe, '_blank');
            }
        },
        downloadZip(id){
            window.open(this.BASE_URL + "/dossier/downloadzip/" + id, '_blank');
        },
        onSubmit() {

            // var link = this.BASE_URL + "/dossier/enregistreroverstay";
            // axios.post(link, $("#formulaire").serialize()).then((response) => {
            //     console.log(response)
            // });
        },
        getFranchise(id_compagnie) {
            this.liste_franchise = [];
            this.nbre_jours_restant = 0;

            if (id_compagnie > 0) {
                axios.get(this.BASE_URL + '/compagnie/get/' + id_compagnie).then((response) => {
                    var liste_franchise = response.data[0].franchise;
                    console.log(liste_franchise);


                    // Date donnée au format 'YYYY-MM-DD'
                    var dateDonnee = new Date(this.Ouverture.ouverture_date_arriver);
                    var dateAujourdhui = new Date();
                    var differenceEnMillisecondes = dateAujourdhui - dateDonnee;
                    // nombre de jours aujourd'hui
                    var differenceEnJours = (Math.floor(differenceEnMillisecondes / (1000 * 60 * 60 * 24))) + 1;
                    console.log("differencejours", differenceEnJours);

                    this.nbre_jours_restant = differenceEnJours;
                    var ordreSelectionne = null;

                    for (var i = 0; i < liste_franchise.length; i++) {
                        var franchise = liste_franchise[i];
                        // console.log("nbre_jours",franchise.nbre_jour)
                        console.log("nbre_jours_restant", differenceEnJours);
                        if (parseInt(franchise.nbre_jour) >= differenceEnJours) {
                            console.log('iteraton', i)
                            ordreSelectionne = franchise.ordre;
                            break;
                        }
                        differenceEnJours = differenceEnJours - parseInt(franchise.nbre_jour);
                    }

                    // var nbre_jours = parseInt(this.nbre_jours_restant);// 10

                    var date_ouverture_date_arriver = this.Ouverture.ouverture_date_arriver;
                    if (ordreSelectionne !== null) {
                        for (var j = 0; j < liste_franchise.length; j++) {
                            var franchises = liste_franchise[j];
                            var date = "";
                            if (franchises.ordre <= ordreSelectionne) {
                                var nbre_jours = parseInt(this.nbre_jours_restant);
                                var franchiseData = [];
                                var iteration_date = 0;
                                for (var jour = 1; jour <= parseInt(franchises.nbre_jour); jour++) {
                                    nbre_jours--;
                                    date = moment(date_ouverture_date_arriver).add(iteration_date, 'days').format('DD/MM/YYYY');
                                    franchiseData.push({
                                        number_jour: jour,
                                        etat_jours: nbre_jours >= 0 ? 'expirer' : 'non_expirer',
                                        date: date,
                                    });
                                    iteration_date++;
                                }
                                franchises.lite_jour = franchiseData;
                                this.liste_franchise.push(franchises);
                                this.nbre_jours_restant = nbre_jours;

                                const [jours, mois, annee] = date.split("/");
                                var date1 = annee + "-" + mois + "-" + jours;
                                console.log("new_date", date1)

                                date_ouverture_date_arriver = moment(date1).add(1, 'days');
                            }
                        }
                    }


                })
            }
            setTimeout(() => {
                this.clickEnregistrer();
            }, 1000)
        },

        getMaxNumberJour(lite_jour) {
            let maxNumberJour = 0;
            for (let jour of lite_jour) {
                if (jour.etat_jours === "expirer" && jour.number_jour > maxNumberJour) {
                    maxNumberJour = jour.number_jour;
                }
            }
            return maxNumberJour;
        },

        calculTotal(f) {
            const maxNumberJour = parseInt(this.getMaxNumberJour(f.lite_jour));
            const tarif = parseFloat(f.tarif);
            const total = maxNumberJour * tarif;
            return isNaN(total) ? 0 : total;
        }

    },

    created: function () {
        this.$emit('change-page', this.pageTitle);
        this.motCle = "";
        this.criteriacol = "";
        this.getAllDossier();
    },
    mounted: function () {
        if (parseInt(this.$route.params.id_dossier) > 0) {
            this.chargeDonneOuverturebyID(this.$route.params.id_dossier);
            this.id_dossier = this.$route.params.id_dossier == undefined ? 0 : this.$route.params.id_dossier;
        }
    }
}
